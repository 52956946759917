import React from 'react'
import '../components/board.css'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Link } from 'gatsby'

export const EmployementDetails = ({ location }) => {


    const { state = {} } = location;
    const { strapiId, jobDetails } = state;

    const strapiJobs = { ...jobDetails };

    const MAX_LENGTH = 100
    return (

        <Layout location={location} crumbLabel="EmploymentDetails" >
            <Link to="/employement">
            {' '}
            <span style={{ fontSize: '25px' }}>←</span> Back to Employment Page
            </Link>
            <div className="box center">
                <div className="box center">
                    <div className="container">
                        <div >
                            <div
                                className="team-player"
                                style={{
                                    backgroundColor: 'rgb(249, 249, 255)',
                                }}
                            >
                                <p className="jobField">
                                    <span className="jobLable">Position Title:</span>{' '}
                                    {strapiJobs.jp_position_title}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable">Application Deadline:</span>{' '}
                                    {strapiJobs.jp_appl_deadline}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable"> Start Date:</span>{' '}
                                    {strapiJobs.jp_start_date}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable"> Location:</span>{' '}
                                    {strapiJobs.jp_location}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable">Position Qualifications:</span>{' '}
                                    {strapiJobs.jp_position_qual}
                                </p>

                                <p className="jobField">
                                    <span className="jobLable"> Contact Name:</span>{' '}
                                    {strapiJobs.jp_contact_name}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable">Contact Email:</span>{' '}
                                    {strapiJobs.jp_contact_email}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable">Contact Phone:</span>{' '}
                                    {strapiJobs.jp_contact_phone}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable">Contact FAX:</span>{' '}
                                    {strapiJobs.jp_contact_fax}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable">Contact VideoPhone:</span>{' '}
                                    {strapiJobs.jp_contact_videophone}
                                </p>
                                <p className="jobField">
                                    <span className="jobLable">Contact Other:</span>{' '}
                                    {strapiJobs.jp_contact_other}
                                </p>

                                <p className="jobField">
                                    <span className="jobLable">Description:</span>{' '}
                                    <span style={{ textAlign: 'justify' }}>
                                        {strapiJobs.jp_position_desc}
                                    </span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default EmployementDetails
